$primary-color: #ED4548;
@import 'animate.css';
@import '~loaders.css/src/animations/ball-clip-rotate.scss';

@font-face{
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('truetype');
}
@font-face{
  font-family: 'Open Sans Light';
  src: url('./assets/fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face{
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}
@font-face{
  font-family: 'Open Sans Regular';
  src: url('./assets/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face{
  font-family: 'Open Sans SemiBold';
  src: url('./assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face{
  font-family: 'Open Sans SemiBoldItalic';
  src: url('./assets/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
}
@font-face{
  font-family: 'Open Sans Bold';
  src: url('./assets/fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face{
  font-family: 'Open Sans Italic';
  src: url('./assets/fonts/OpenSans-Italic.ttf') format('truetype');
}
@font-face{
  font-family: 'Open Sans LightItalic';
  src: url('./assets/fonts/OpenSans-LightItalic.ttf') format('truetype');
}

input, textarea {
font-family: 'Open Sans Regular';
}


.loader-active{
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}
