.page {
    width: 100%;
    height: 100%;
    min-width: 900px;
    position: absolute;
}
.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 500px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}
.about-page{
    .text-zone{
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        width: 35%;
        display: table-cell;
        max-height: 100%;
        text-align: center;
        margin-left: 2%;
        margin-top: 3%;
        z-index: 3;
        min-width: 400px;
        padding-top: 4%;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            width: 0em;
          }
        h1 {
            font-size: 50px;
            font-family: 'Coolvetica';
            color:black;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 8%;
        }

        .aboutText{
            text-align: justify;
            width: 70%;
            height: 100%;
            margin-left: 15%;
            margin: auto;
            height: 100%;
            


            p {
                color: black;
                font-family: 'Open Sans Regular';
                font-weight: 400;
                font-size: 15px;
                animation: pulse 1s;
    
                &:nth-of-type(1){
                    animation-delay: 1.1s;
                }
                
                &:nth-of-type(2){
                    animation-delay: 1.2s;
                }
                
                &:nth-of-type(3){
                    animation-delay: 1.3s;
                }
            }
        }


        .text-animate-hover{
            &:hover{
                color:#ED4548;
            }
        }
        .profile-img {
            border-radius: 50% ;
            width: 35%;
            display: block;
            margin: 0 auto;
            position: relative;
            margin-bottom: 20px;
            min-height: 120px;
            min-width: 120px;
            outline: 5px solid #20201E;
            outline-offset: -4px;
        }
        
    }
    .project-zone{
        width: 50%;
        height: 100%;
        margin-left: 40%;
        text-align: center;

        h1 {
            width: 100%;
            margin: auto;
            font-size: 50px;
            font-family: 'Coolvetica';
            color:black;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            transition: 0.4s;

            .text-animate-hover{
                &:hover{
                    color:#ED4548;
                }
            }
            .close-button{
                color: black;
                background: none;
                padding: 10px 22px;
                border: 2px solid black;
                white-space: nowrap;
                .back-arrow{
                    border: solid black;
                    border-width: 0 3px 3px 0;
                    display: inline-block;
                    padding: 4px;
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                }
            
                &:hover{
                    background: #ED474A;
                    color: #0a0a0a;
                    box-shadow: 0.1vw 0.1vw 0.2vw black;
                    font-weight: 400;
                    transition: 0.4s;
                    cursor: pointer;
                }
            }
        }
        .projects{
            display: inline-block;
            margin: auto;
            position: relative;
            overflow-x: hidden;
            border-left: 4px solid #BABAB5;
            width: 100%;
            height: 90%;
            overflow-y: auto;
            min-width: 400px;
            padding-right: 10%;
            padding-left: 50px;
        }
        .project-info-zone{
            text-align: center;
            width: 100%;
            min-width: 400px;


            .infoWrapper{
                border-left: 4px solid #BABAB5;
                position: absolute;
                height: 90%;
                width: 51%;
                overflow-x: hidden;
                overflow-y: auto;
                padding-right: 4%;
                padding-left: 50px;

                .infoContent{
                    width: 100%;
                    display: inline-block;
            
                }

                .infoText{
                    text-align: justify;
                    width: 100%;
                    display: inline-block;
                    
                    p {
                        color: black;
                        font-family: 'Open Sans Regular';
                        font-weight: 400;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .page{
        min-width: 300px;
    }
    .about-page{
        display: inline-block;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: none;
        &::-webkit-scrollbar{
            width: 0em;
          }
        .text-zone{
            position: absolute;
            width: 100%;
            display: block;
            max-height: none;
            text-align: center;
            top: 200%;
        }
        .project-zone{
            position: relative;
            width: 100%;
            max-height: none;
            text-align: center;
            margin-left: 4%;
            h1 {
                width: 50%;
                height: 10%;
                margin: auto;
                font-size: 50px;
                font-family: 'Coolvetica';
                color:black;
                font-weight: 400;
                margin-top: 0;
                position: relative;
                margin-bottom: 20px;
                transition: 0.4s;
                margin-bottom: 18%;

    
                .text-animate-hover{
                    &:hover{
                        color:#ED4548;
                    }
                }
                .close-button{

                    color: black;
                    background: none;
                    padding: 5px 25px;
                    border: 2px solid black;
                    white-space: nowrap;

                    .back-arrow{
                        border: solid black;
                        border-width: 0 3px 3px 0;
                        display: inline-block;
                        padding: 4px;
                        transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                    }
                
                    &:hover{
                        background: #ED474A;
                        color: #0a0a0a;
                        box-shadow: 0.1vw 0.1vw 0.2vw black;
                        font-weight: 400;
                        transition: 0.4s;
                        cursor: pointer;
                    }
                }
            }
            .projects{
                display: inline-block;
                margin: auto;
                position: relative;
                overflow-x: hidden;
                border-left: 0px solid #BABAB5;
                width: 70%;
                height: 90%;
                overflow-y: auto;
                min-width: 300px;
                margin-left: 3%;
                padding-right: 3%;
                padding-left: 0%;
                &::-webkit-scrollbar{
                    width: 1.5em;
                  }
            }

            .project-info-zone{
                text-align: center;
                width: 100%;
                min-width: 250px;
                margin-left: 5%;
    
                .infoWrapper{
                    border-left: 0px solid #BABAB5;
                    position: absolute;
                    height: 90%;
                    width: 70%;
                    overflow-x: hidden;
                    overflow-y: auto;
                    &::-webkit-scrollbar{
                        width: 0em;
                      }
    
                    .infoContent{
                        width: 100%;
                        display: inline-block;
                
                    }
    
                    .infoText{
                        text-align: justify;
                        width: 100%;
                        display: inline-block;
                        
                        p {
                            color: black;
                            font-family: 'Open Sans Regular';
                            font-weight: 400;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

}

