h1{
    img{
    width: 32px;
    margin-left: 20px;
    height: auto;
    }
}
.pHeadImg{
    width: 28px;
    height: auto;
}
