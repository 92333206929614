html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 200 11px/1.4 'sans-serif';
  color: #444;
  background:#EDE1D4;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar{
    width: 2em;
  }
  ::-webkit-scrollbar-track{
    background: #20201E;
    border-radius: 100vw;
    margin-block: .5em;
  }
  ::-webkit-scrollbar-thumb{
    background: #625A55;
    border: .25em solid #20201E;
    border-radius: 100vw;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: hsl(120 100% 10% /1);
  }
}
