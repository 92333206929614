.contact-page{
    .text-zone{
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 35%;
        margin: auto;
        display: table-cell;
        max-height: 90%;
        text-align: center;

        h1 {
            font-size: 50px;
            font-family: 'Coolvetica';
            color:black;
            font-weight: 400;
            margin-top: 0;
            position: relative;
        }
        .contactText{
            text-align: left;
            margin-bottom: 40px;
            margin-left: 5%;
            p {
                color: black;
                font-family: 'Open Sans Regular';
                font-weight: 400;
                font-size: 14px;
                min-width: fit-content;
                animation: pulse 1s;
    
                &:nth-of-type(1){
                    animation-delay: 1.1s;
                }
                
                &:nth-of-type(2){
                    animation-delay: 1.2s;
                }
                
                &:nth-of-type(3){
                    animation-delay: 1.3s;
                }
            }

        }
        .text-animate-hover{
            &:hover{
                color:#ED4548;
            }
        }
        .profile-img {
            border-radius: 50%;
            width: 35%;
            display: block;
            margin: 0 auto;
            position: relative;
            margin-bottom: 20px;
            min-height: 120px;
            min-width: 120px;
        }
        
    }

    .contact-form{
        width: 100%;
        margin-top: 20px;
        margin-left: 5%;
    
        ul{
            padding: 0;
            margin: 0;
    
            li{
                padding: 0;
                margin: 0;
                list-style: none;
                margin-bottom: 10px;
                overflow: hidden;
                display: block;
                position: relative;
                opacity: 0;
                animation: fadeInUp 1.4s 1.4s;
                animation-fill-mode: forwards;
                clear: both;
            }
    
            li.half{
                width: 49%;
                margin-left: 2%;
                float: left;
                clear: none;
    
                &:first-child{
                    margin-left:0;
                }
    
            }
        }
    
        input[type='text'],
        input[type='email'] {
            width: 100%;
            border: 0;
            height: 50px;
            font-size: 16px;
            font-family: sans-serif;
            color: #000;
            padding: 0 20px;
            box-sizing: border-box;
            border: 1px solid #20201E;

            &:focus{
                outline: none;
            }
        }
    
        textarea{
            width: 100%;
            border: 0;
            height: 50px;
            font-family: sans-serif;
            font-size: 16px;
            color: #000;
            padding: 20px;
            min-height: 150px;
            box-sizing: border-box;
            border: 1px solid #20201E;
        }
        .flat-button{
            color: black;
            background: none;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 3px;
            font-family: sans-serif;
            text-decoration: none;
            border: 1px solid black;
            float: right;
            animation: fadeIn 1s 0.5s backwards;
            white-space: nowrap;
            padding: 8px 20px;
            margin-bottom: 1%;
        
            &:hover{
                background: #ED474A;
                color: #0a0a0a;
                cursor: pointer;
                font-weight: 600;
            }
        }
    }

}
@media only screen and (max-width: 600px) {
    .contact-page{
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100%;
        &::-webkit-scrollbar{
            width: 0em;
          }
        .text-zone{
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            display: table-cell;
            max-height: 90%;
            text-align: center;
    
            h1 {
                font-size: 50px;
                font-family: 'Coolvetica';
                color:black;
                font-weight: 400;
                margin-top: 0;
                position: relative;
            }
            .contactText{
                text-align: justify;
                margin-bottom: 2%;
                margin-left: 5%;
                p {
                    color: black;
                    font-family: 'Open Sans Regular';
                    font-weight: 400;
                    font-size: 14px;
                    min-width: fit-content;
                    animation: pulse 1s;
        
                    &:nth-of-type(1){
                        animation-delay: 1.1s;
                    }
                    
                    &:nth-of-type(2){
                        animation-delay: 1.2s;
                    }
                    
                    &:nth-of-type(3){
                        animation-delay: 1.3s;
                    }
                }
    
            }
            .text-animate-hover{
                &:hover{
                    color:#ED4548;
                }
            }
            .profile-img {
                border-radius: 50%;
                width: 35%;
                display: block;
                margin: 0 auto;
                position: relative;
                margin-bottom: 20px;
                min-height: 120px;
                min-width: 120px;
            }
            
        }
    
        .contact-form{
            width: 100%;
            margin-top: 20px;
            margin-left: 5%;
        
            ul{
                padding: 0;
                margin: 0;
        
                li{
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    margin-bottom: 10px;
                    overflow: hidden;
                    display: block;
                    position: relative;
                    opacity: 0;
                    animation: fadeInUp 1.4s 1.4s;
                    animation-fill-mode: forwards;
                    clear: both;
                }
        
                li.half{
                    width: 49%;
                    margin-left: 2%;
                    float: left;
                    clear: none;
        
                    &:first-child{
                        margin-left:0;
                    }
        
                }
            }
        
            input[type='text'],
            input[type='email'] {
                width: 100%;
                border: 0;
                height: 50px;
                font-size: 16px;
                font-family: sans-serif;
                color: #000;
                padding: 0 20px;
                box-sizing: border-box;
                border: 1px solid #20201E;
    
                &:focus{
                    outline: none;
                }
            }
        
            textarea{
                width: 100%;
                border: 0;
                height: 50px;
                font-family: sans-serif;
                font-size: 16px;
                color: #000;
                padding: 20px;
                min-height: 100px;
                box-sizing: border-box;
                border: 1px solid #20201E;
            }
            .flat-button{
                color: black;
                background: none;
                font-size: 13px;
                font-weight: 600;
                letter-spacing: 3px;
                font-family: sans-serif;
                text-decoration: none;
                border: 1px solid black;
                float: right;
                animation: fadeIn 1s 0.5s backwards;
                white-space: nowrap;
                padding: 8px 20px;
                margin-bottom: 1%;
            
                &:hover{
                    background: #ED474A;
                    color: #0a0a0a;
                    cursor: pointer;
                    font-weight: 600;
                }
            }
        }
    }
}
