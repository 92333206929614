.website-info{
    padding-bottom: 10%;

    .codeImg{
        width: 100%;
        margin-top: 2%;

    }
    .reactImg{
        width: 100%;
        margin-top: 2%;

    }
}