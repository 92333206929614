.project{
    min-width: 350px;
    min-height: 380px;
    width: 40%;
    padding-bottom: 5%;
    padding-top: 8%;
    height: 50%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 2%;
    background: #40403B;
    animation: fadeInUp 1.4s 1s;
    animation-fill-mode: forwards;
    border: 6px solid #20201E;
    border-radius: 1%;

    img {
        width:  100%;
        height: 70%;
        object-fit: cover;
        margin: auto;

    }


    &:hover{
        transition: 0.4s;
        cursor: pointer;
        background: #75756C;
        box-shadow: 0.1vw 0.1vw 0.2vw black;
    }

    .pHeader{
        position: absolute;
        text-align: center;
        transform: translateY(-50%);
        width: 100%;
        top: 4.8%;
        background:#20201E;
        min-height: 40px;
        height: 10%;
        display: table;
        box-shadow: 0.0vw 0.1vw 0.1vw black; 

        .bGroundRed{
            height: 100%;
            position: absolute;
            background:#ED4548;
            display: table;
            .textRed {
                font-family: "Coolvetica";
                color: black;
                font-size: 25px;
                text-align:center; 
                vertical-align: middle;
                display: table-cell; 
            }
        }
        .bGroundBlue{
            height: 100%;
            position: absolute;
            background:#618BC2;
            display: table;
            .textBlue {
                font-family: "Coolvetica";
                color: black;
                font-size: 25px;
                text-align:center; 
                vertical-align: middle;
                display: table-cell; 
            }
        }


        .test{
            padding-left: 40px;
            font-size: 23px;
            font-family: 'Coolvetica';
            color:#EBEBEB;
            letter-spacing: 1px;
            height: 100%;
            width: 100%;
            text-align:center; 
            vertical-align: middle;
            display: table-cell; 
        }
    }

    .text-animate-hover{
        &:hover{
            color:#fff;
        }
    }
    .borderDesc{
        height: 20%;
        margin-top: 5%;
        display: table; 
        width: 100%;
        background: #20201E;
    }
    p{
        padding-left: 20px;
        padding-right: 20px;
        color:white;
        font-family: 'Open Sans Italic';
        font-weight: 400;
        font-size: 15px;
        text-align:center; 
        vertical-align: middle;
        display: table-cell;
    }

}
.img-text{
    color: black;
    font-family: 'Open Sans SemiBoldItalic';
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-align: center;
    margin-bottom: 10%;
    margin-top: 3%;
}
h3 {
    font-size: 25px;
    font-family: 'Coolvetica';
    color: black;
    font-weight: 400;
    letter-spacing: 1px;
    position: relative;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .project{
        min-width: 250px;
        min-height: 280px;
        width: 50%;
        padding-bottom: 0%;
        padding-top: 8%;
        height: 80%;
        max-height: 320px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 2%;
        background: #40403B;
        animation: fadeInUp 1.4s 1s;
        animation-fill-mode: forwards;
        border: 6px solid #20201E;
        border-radius: 1%;
    
        img {
            width:  100%;
            height: 70%;
            max-height: 240px;
            object-fit: cover;
            margin: auto;
    
        }
    
    
        &:hover{
            transition: 0.4s;
            cursor: pointer;
            background: #75756C;
            box-shadow: 0.1vw 0.1vw 0.1vw black;
        }
    
        .pHeader{
            position: absolute;
            text-align: center;
            transform: translateY(-50%);
            width: 100%;
            top: 4.8%;
            background:#20201E;
            min-height: 20px;
            height: 10%;
            display: table;
            box-shadow: 0.0vw 0.1vw 0.1vw black; 
    
            .bGroundRed{
                height: 100%;
                position: absolute;
                background:#ED4548;
                display: table;
                .textRed {
                    font-family: "Coolvetica";
                    color: black;
                    font-size: 18px;
                    text-align:center; 
                    vertical-align: middle;
                    display: table-cell; 
                }
            }
            .bGroundBlue{
                height: 100%;
                position: absolute;
                background:#618BC2;
                display: table;
                .textBlue {
                    font-family: "Coolvetica";
                    color: black;
                    font-size: 18px;
                    text-align:center; 
                    vertical-align: middle;
                    display: table-cell; 
                }
            }
    
    
            .test{
                padding-left: 22%;
                font-size: 18px;
                font-family: 'Coolvetica';
                color:#EBEBEB;
                letter-spacing: 1px;
                height: 100%;
                width: 100%;
                text-align:center; 
                vertical-align: middle;
                display: table-cell; 
            }
        }
    
        .text-animate-hover{
            &:hover{
                color:#fff;
            }
        }
        .borderDesc{
            height: 15%;
            margin-top: 5%;
            display: table; 
            width: 100%;
            background: #20201E;
        }
        p{
            padding-left: 20px;
            padding-right: 20px;
            color:white;
            font-family: 'Open Sans Italic';
            font-weight: 400;
            font-size: 13px;
            text-align:center; 
            vertical-align: middle;
            display: table-cell;
        }
    
    }
    .img-text{
        color: black;
        font-family: 'Open Sans SemiBoldItalic';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-align: center;
        margin-bottom: 10%;
        margin-top: 3%;
    }
    h3 {
        font-size: 25px;
        font-family: 'Coolvetica';
        color: black;
        font-weight: 400;
        letter-spacing: 1px;
        position: relative;
        text-align: left;
    }
}
