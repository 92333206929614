.closeButtonWrapper{
    position: absolute;
    height: 100%;
    z-index: 3;
    bottom: 15%;
}
.back-arrow{
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4px;
        margin: 4px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
}

@media only screen and (max-width: 600px) {
    .closeButtonWrapper{
        position: relative;
        height: 100%;
        z-index: 3;
        bottom: 15%;
        margin-top: 4%;
    }

}