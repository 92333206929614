.ubg-info{
    padding-bottom: 10%;

    .UGB1{
        width: 40%;
        margin-top: 2%;
        border: 5px solid #20201E;

    }
    .UGB-2{
        width: 75%;
        margin-top: 2%;


    }
    .UGBTN{
        width: 100%;
        margin-top: 2%;


    }
}