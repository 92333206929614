.home-page{
    .text-zone{
        position: relative;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }
    h1{
        color: black;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        img{
            width: 28px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 0.5s linear both; 
            animation-delay: 1s;
        }
    }
    .mobileImg{
        margin-top: 5%;
        margin-bottom: 5%;
        content-visibility: hidden;
        .logo-container{
            .image-logo{
                width: 100%;
            }   
    }
    }
}
h2 {
    color: #20201E;
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Open Sans Regular';
    letter-spacing: 2px;
    animation: fadeIn 1s 0.5s backwards;
}

.flat-button{
    color: black;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 3px;
    font-family: Open Sans Regular;
    text-decoration: none;
    padding: 10px 18px;
    border: 1.5px solid black;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 0.5s backwards;
    margin-right: 2%;
    transition-duration: 0.2s;

    &:hover{
        background: #ED4548;
        color: #0a0a0a;
        font-weight: 600;
        box-shadow: 0.1vw 0.1vw 0.2vw black;
    }
}
.logoContainer{
    animation: fadeIn 1s linear both; 
    animation-delay: 1s;

    .logo-container{
        .image-logo{
            float: right;
            margin-right: 15%;
        }   
}
}

@media only screen and (max-width: 600px) {
    .home-page {
        min-width: 200px;
        .text-zone{
            top: 45%;
            width: 50%;
            left: 5%;
            margin: auto;
            max-height: 100%;
            text-align: center;
            .mobileImg{
                content-visibility: visible;
                margin-top: 10%;
            }
        }
        .flat-button{
            width: 70%;
            text-align: center;
            color: black;
            float: none;
            display: inline-block;
        }
        h2{
            width: 100%;
        }
    }
    .logoContainer{
        content-visibility: hidden
    }
  }